import config from '../config/config'
class BotHelper {
    constructor() {}
    
    async queryBot(message = "", bot = 0)
    {
      // console.log(`the server url is ${config.localAuth.apiServiceUrl}`)
      // console.log(`process env `,process.env.NODE_ENV)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        "text": message
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      let botVersion=process.env.REACT_APP_CHAT_BOT_ENDPOINT.split(',')
      let queryBotType = botVersion[bot]
      
      const r = await fetch(`${config.localAuth.apiServiceUrl}${queryBotType}`, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => false);
      return r
    }

    async querySurveyBot(qIndex = 0)
    {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        "text": qIndex
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      // let botVersion=process.env.REACT_APP_CHAT_BOT_ENDPOINT.split(',')
      // let queryBotType = botVersion[bot]
      
      const r = await fetch(`${config.localAuth.apiServiceUrl}${process.env.REACT_APP_SURVEY_ENDPOINT}`, requestOptions)
        .then(response => response.json())
        .then(result => result?.response)
        .catch(error => false);
      return r
    }

}

export default new BotHelper()