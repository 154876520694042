const en = require('./en.json')
const ja = require('./ja.json')

export const defaultLang =  "ja";
export const langPack = {
    en: en, 
    ja: ja
}

const initLang = (lang = defaultLang) => {
    return langPack[lang]
}

export default initLang