const config = {
    localAuth: {
        apiServiceUrl: process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_LOCAL_API_HOST:process.env.REACT_APP_API_HOST
    },
    // defaultLang: process.env.REACT_APP_DEFAULT_LANG,
    storageKey: {
        loginKey: 'xtokenDashboard',
        userAccDetails:'userAccDetailsDashboard'
    },
    tokenRefreshTime: 6*24*60*60, //if it is more than 6 days, need to refresh app to log in
    version: process.env.REACT_APP_VERSION,
    sendPanelHeight: 72,
    optionPanelHeight: 192,
    headerHeight: 64,
    typingLatency: 1500
}


export default config
