import React, { Component, useEffect, useState } from 'react'
import {
    Grid,
    Typography,
    Drawer,
    Toolbar,
    Divider,
    Box,
    Stack,
    CssBaseline,
    List,
    ListItem,
    Avatar
} from '@mui/material'
import * as Loaders from '../../components/UIComponents/Loader'
import * as Icons from '../../components/UIComponents/Icons'
import * as Texts from '../../components/UIComponents/Text'
import * as Images from '../../components/UIComponents/Images'
import AlwaysScrollToBottom from '../../components/AlwaysScrollToBottom'
import TypingAnimation from '../../components/UIComponents/TypingAnimation'
import moment from 'moment'
import { botImgObj } from '../ChatRoom'

const ChatWindow = ({
    messageArr
}) => {

    if (!messageArr || messageArr.length <= 0) {
        return <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
                width: 1,
                flexGrow: 1,
                px: 2
            }}
        >
            <Box>
                <Images.NurseAvatar
                    style={{
                        opacity: .8
                    }}
                />
            </Box>
            <Box>
                <Texts.Paragraph>
                    Have any questions? I am here to help.
                </Texts.Paragraph>
            </Box>
        </Stack>
    }
    // console.log("received msg", messageArr)
    let renderMessage = []
    messageArr.forEach((m, i) => {
        if (m?.source === "user") {
            return renderMessage.push(<UserBubble
                key={`chat-bubble-${i}`}
                message={m?.message}
                time={m?.time}
            />)
        }
        return renderMessage.push(<BotBubble
            key={`chat-bubble-${i}`}
            message={m?.message}
            time={m?.time}
            image={typeof m?.image === "string" ? botImgObj[m?.image]:m?.image}
            typing={true}
        />)
        // return setTimeout(() => {
        //     renderMessage.push(<BotBubble
        //         key={`chat-bubble-${i}-2`}
        //         message={m?.message}
        //         time={m?.time}
        //         image={m?.image}
        //         typing={false}
        //     />)
        //     console.log("to render",renderMessage)
        // }, 1500)
    })

    return <Stack
        direction="column"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{
            width: 1,
            px: 2,
            // bgcolor: 'secondary.main',
            pb: 3
        }}
    >
        {renderMessage}
        <AlwaysScrollToBottom />
    </Stack>


}

const BotBubble = ({
    idx,
    message,
    timestamp,
    time,
    image,
    // typing = false,
    displayTime = 1500
}) => {
    const [display, updateDisplay] = useState(false)

    useEffect(() => {
        // how about always add a typing bubble?
        setTimeout(() => {
            updateDisplay(true)
        }, displayTime)
    }, [])

    // console.log("images", image)
    // console.log("is typing", typing)
    if (display) {
        return (<Stack
            direction="column"
            justifyContent={"flex-start"}
            sx={{
                width: 1,
                ml: 2,
                mt: 2
            }}
        >
            <React.Fragment>
                <Box
                    sx={{
                        p: 0,
                        m: 0
                    }}
                >
                    <Texts.Tip
                        sx={{
                            p: 0,
                            m: 0
                        }}
                    >{moment(time).format('hh:mm') !== "Invalid date" ? moment(time).format('hh:mm') : time}</Texts.Tip>
                </Box>
                <Stack direction={"row"}>
                    <Box>
                        {image}
                    </Box>
                    <Box
                        sx={{
                            maxWidth: .6,
                            bgcolor: 'primary.main',
                            borderRadius: '0px 16px 16px 16px',
                            p: 2,
                            ml: 1
                        }}
                    >
                        <Texts.Paragraph
                            sx={{
                                color: 'primary.contrastText',
                            }}
                        >
                            {message}
                        </Texts.Paragraph>
                    </Box>
                </Stack>
            </React.Fragment>
            <AlwaysScrollToBottom />
        </Stack>)
    }
    return (<Stack
        direction="column"
        justifyContent={"flex-start"}
        sx={{
            width: 1,
            ml: 2,
            mt: 2
        }}
    >
        <Stack direction={"row"}>
            <Box>
                {image}
            </Box>
            <Box
                sx={{
                    maxWidth: .6,
                    bgcolor: 'primary.main',
                    borderRadius: '0px 16px 16px 16px',
                    p: 2,
                    ml: 1
                }}
            >
                <TypingAnimation fill="#F8F8F8" />
            </Box>
        </Stack>
    </Stack>)
}

const UserBubble = ({
    idx,
    message,
    timestamp,
    time
}) => {
    return (
        <Stack
            direction="column"
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            sx={{
                width: 1,
                // mr: 2,
                mt: 2
            }}
        >
            <Box
                sx={{
                    p: 0,
                    m: 0
                }}
            >
                <Texts.Tip
                    sx={{
                        p: 0,
                        m: 0
                    }}
                >{moment(time).format('hh:mm') !== "Invalid date" ? moment(time).format('hh:mm') : time}</Texts.Tip>
            </Box>
            <Box
                sx={{
                    maxWidth: .6,
                    bgcolor: 'background.default',
                    borderRadius: 2,
                    boxShadow: 2,
                    borderRadius: '16px 16px 0px 16px',
                    borderColor: 'primary.light',
                    p: 2
                }}
            >
                <Texts.Paragraph
                    sx={{
                        color: 'primary.main'
                    }}
                >
                    {message}
                </Texts.Paragraph>
            </Box>
        </Stack>)
}

export default ChatWindow