import { createSlice, createAction } from '@reduxjs/toolkit'

const initSurvey = {
    messageArr: [],
    currentIndex: 0
}

const surveyReducer = createSlice({
    name: 'survey',
    initialState: initSurvey,
    reducers: {
        updateSurveyIndex: (state, action) => {
            state.currentIndex = action.payload
        },
        addAnswer: (state, action) => {
            // console.log("received action", action)
            state.messageArr.push(action.payload)
            // return action.payload
            return
        },
        updateSurvey: (state, action) => {
            state.messageArr = action.payload
        },
        clearSurvey: state => {
            return initSurvey
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(createAction("RESET_REDUCER"), (state, action) => {
            // console.log("user reducer receives action", action)
            state=initSurvey
          })
          .addDefaultCase((state, action) => {
            state = initSurvey
          })
    },
})
  
// Action creators are generated for each case reducer function
export const { addAnswer, updateSurvey, clearSurvey, updateSurveyIndex } = surveyReducer.actions

export default surveyReducer.reducer