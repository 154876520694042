// import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { createTheme, ThemeProvider, styled, responsiveFontSizes } from '@mui/material/styles'
const ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#003893',
      dark: '#00235C',
      contrastText: '#FFFFFF',
      light: '#E6EBF5',
    },
    secondary: {
      main: '#00CDD8',
      light: '#6CF8FF',
      dark: '#003336',
      contrastText: '#FFFFFF',
    },
    call2action: {
      main: '#F9DC5C',
      light: '#ffff8d',
      dark: '#c3ab29',
      contrastText: '#000000',
    },
    error: {
      main: '#ED254E',
    },
    info: {
      main: '#1F3232',
    },
    text: {
      primary: '#003893',
      secondary: '#00CDD8',
      light: '#F5FBFF'
    },
    background: {
      default: '#ffffff',
      gray: '#E6EBF5',
      highlight: '#6CF8FF',
      dark: '#003893',
    },
    divider: '#528586',
    warning: {
      main: '#F9DC5C',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Playfair Display',
      fontSize: '67pt',
      lineHeight: 1.2
      // lineHeight: '120%'
    },
    fontFamily: 'Raleway',
    h2: {
      fontFamily: 'Playfair Display',
      fontSize: '51pt',
      lineHeight: 1.2

      // lineHeight: '120%'
    },
    h3: {
      fontFamily: 'Playfair Display',
      fontSize: '38pt',
      lineHeight: 1.2

      // lineHeight: '120%'
    },
    h4: {
      fontFamily: 'Playfair Display',
      fontSize: '28pt',
      lineHeight: 1.2

      // lineHeight: '120%'
    },
    h5: {
      fontFamily: 'Playfair Display',
      fontSize: '21pt',
      lineHeight: 1.2

      // lineHeight: '120%'
    },
    fontSize: 14,
  },
  spacing: 6,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  drawerWidth: 250,
  questionnaireTitleWidth: 320,
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: '#689f38',
        color: '#fff',
      },
    },
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: '1px solid #bdbdbd',
        backgroundColor: '#fafafa',
        opacity: 1,
        transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  props: {
    MuiAppBar: {
      color: 'inherit',
    },
    MuiTooltip: {
      arrow: true,
    },
  },
}

const theme = responsiveFontSizes(createTheme(ThemeOptions))

const CustomTheme = props => <ThemeProvider theme={theme}>
    {props.children}
</ThemeProvider>

export default CustomTheme