import config from '../config/config'
import AuthenticationHelper from './AuthenticationHelper'
import GeneralHelper from './GeneralHelper'

class AuthRequest{

    //make a request using the stored token
    request(mode,location,params){
        //cases when mode requires body
        let requestOptions = {
            method: mode.toUpperCase(),
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Authorization": "Bearer "+AuthenticationHelper.token
            }
        }
        let url=config.localAuth.apiServiceUrl+location;
        //console.log(url)
        if(mode.toLowerCase()==="get")
        {
            let getQueryString= GeneralHelper.serializeGetUrl(params)
            url=url+"?"+getQueryString
        }

        //console.log(mode.toLowerCase())
        if(mode.toLowerCase()==="post" || mode.toLowerCase()==="put" || mode.toLowerCase()==="patch" || mode.toLowerCase()==="delete"  ) {
            requestOptions.body= JSON.stringify(params)
            requestOptions.headers["Content-Type"]="application/json";
        }
        let response_status=0
        return fetch(url,requestOptions)
        .then(r => {
            response_status=r.status
            return r.json()
        })
        .then(response => {
            if(response_status!==200)//error
            {
                if (response_status===401){
                    throw new Error ("Invalid Credentials")
                }
                else{
                    throw new Error ("Unspecified server error for path=/"+location+' , status:'+ response.code);
                }
            }
            else
            {
                return response
            }
        })
    }

    //make a request to download file 
    requestFile(mode,location,params,filename){
        //cases when mode requires body
        let requestOptions = {
            method: mode.toUpperCase(),
            mode: "cors",
            cache: "no-cache",
            headers: {
                "Authorization": "Bearer "+AuthenticationHelper.token
            }
        }
        let url=config.localAuth.apiServiceUrl+location;
        //console.log(url)
        if(mode.toLowerCase()==="get")
        {
            let getQueryString= GeneralHelper.serializeGetUrl(params)
            url=url+"?"+getQueryString
        }

        //console.log(mode.toLowerCase())
        if(mode.toLowerCase()==="post" || mode.toLowerCase()==="put" || mode.toLowerCase()==="patch" || mode.toLowerCase()==="delete"  ) {
            requestOptions.body= JSON.stringify(params)
            requestOptions.headers["Content-Type"]="application/json";
        }
        let response_status=0
        return fetch(url,requestOptions)
        .then(r => {
            response_status=r.status
            return r.blob()
        })
        .then(blob => {
            if(response_status!==200)//error
            {
                if (response_status===401){
                    throw new Error ("Invalid Credentials")
                }
                else{
                    throw new Error ("Unspecified server error for path=/"+location);
                }
            }
            else
            {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                return true
            }
        })
    }

    /*Make a request not using the token*/
    requestNoToken(mode,location,params){
        //cases when mode requires body
        let requestOptions = {
            method: mode.toUpperCase(),
            mode: "cors",
            cache: "no-cache",
            headers: {}
        }
        let url=config.localAuth.apiServiceUrl+location;
        if(mode.toLowerCase()==="get")
        {
            let getQueryString= GeneralHelper.serializeGetUrl(params)
            url=url+"?"+getQueryString
        }

        if(mode.toLowerCase()==="post" || mode.toLowerCase()==="put" || mode.toLowerCase()==="patch" || mode.toLowerCase()==="delete"  ) {
            requestOptions.body= JSON.stringify(params)
            requestOptions.headers["Content-Type"]="application/json";
        }
        let response_status=0
        return fetch(url,requestOptions)
        .then(r => {
            response_status=r.status
            return r.json()
        })
        .then(response => {
            if(response_status!==200)//error
            {
                // throw new Error ("Unspecified server error for path=/"+location+' , status:'+ response.code);
                throw new Error(response.code)
            }
            else
            {
                return response
            }
        })
    }
}

export default new AuthRequest();