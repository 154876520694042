import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { withRouter } from 'react-router';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import * as Icons from './UIComponents/Icons'
import * as Texts from './UIComponents/Text'
import * as Images from './UIComponents/Images'
import * as Buttons from './UIComponents/Button'
import CustomTheme from './CustomTheme';
import IconButton from '@mui/material/IconButton'
import config from '../config/config';

const AppNavigator2 = ({
    title,
    sx,
    image,
    onBackClicked
}) => {

    return (<CustomTheme>
        <AppBar position="static"
            sx={{
                // py: 1,
                px: 1,
                ...sx
            }}
            style={{
                maxHeight: config.headerHeight
                // paddingTop: `env(safe-area-inset-top)`,
                // paddingLeft: `env(safe-area-inset-left)`,
                // paddingRight: `env(safe-area-inset-right)`,
                // paddingBottom: `env(safe-area-inset-bottom)`,
            }}
        >
          <Toolbar
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...sx
            }}
          >
            <IconButton color={"inherit"} aria-label="back to previous page" component="label"
                onClick={onBackClicked}
            >
                <Icons.BackIcon />
            </IconButton>
            <Texts.Paragraph
                sx={{
                    color: 'inherit',
                    flexGrow: 1,
                    textAlign: 'center'
                }}
            >{title}</Texts.Paragraph>
            {/*<Images.NursePointerRight />*/}
            {image}
          </Toolbar>
        </AppBar>
      </CustomTheme>
    );
}

export default withRouter(AppNavigator2)